export const ChatMessageType = Object.freeze({
    PLAIN_TEXT: "plaintext",
    HTML: "html",
    CHAT_EVENT: "chat_event",
    CONSULTATION_CTA: "consultation_cta"
});

export const ChatMessageAuthorType = Object.freeze({
    USER: "user",
    CUSTOMER: "customer",
    SYSTEM: "system"
});

export const ChatMessageEventName = Object.freeze({
    CHAT_STARTED: "chat_started",
    CHAT_ACTIVATED: "chat_activated",
    ASSIGNED_USER_CHANGED: "assigned_user_changed",
    CHAT_INACTIVATED: "chat_inactivated",
    CHAT_ARCHIVED: "chat_archived",
    CHAT_REACTIVATED: "chat_reactivated"
});

export const ChatMessageStatus = Object.freeze({
    CREATED: "created",
    SENT: "sent",
    DELIVERED: "delivered",
    READ: "read"
});
