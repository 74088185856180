import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import chat from "./modules/chat";
import { EventBus } from "../components/EventBus";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
        API_BASE_PATH: process.env.VUE_APP_API_BASE_PATH,
        SELF_URL: process.env.VUE_APP_SELF_URL,

        storage: {},
        messages: [],

        pluralization_messages: {
            minutes: ["0 minut", "1 minuta", "{n} minuty", "{n} minut"],
            hours: ["", "1 godzina", "{n} godziny", "{n} godzin"],
            days: ["", "1 dzień", "{n} dni", "{n} dni"]
        },

        app_booted: false,
        initial_layout_set: false,

        cw_application: null, // Miejsce na obiekt obiektu Application, który został przez użytkownika wybrany do pracy
        cw_application_promise_handle: null, // handle dla Promise sprawdzającego, czy Application zostało wybrane

        service_worker_available: false
    },

    getters: {
        pluralize: state => obj => {
            if (obj.n === undefined || isNaN(obj.n)) return "INVALID_N_PROPERTY";

            function getChoiceIndex(choice) {
                if (choice === 0) return 0;
                if (choice === 1) return 1;

                const teen = choice > 10 && choice < 20;
                const endsWithTwoThreeOrFour = choice % 10 >= 2 && choice % 10 <= 4;

                if (teen || (!teen && !endsWithTwoThreeOrFour)) return 3;
                return 2;
            }
            const x = getChoiceIndex(obj.n);

            if (obj.values && Array.isArray(obj.values)) {
                if (x < obj.values.length) return obj.values[x].replace("{n}", obj.n);
                else return "INVALID_VALUES_LENGTH";
            } else if (obj.message && typeof obj.message == "string") {
                if (
                    state?.pluralization_messages[obj.message] &&
                    Array.isArray(state.pluralization_messages[obj.message]) &&
                    state.pluralization_messages[obj.message].length >= x
                )
                    return state.pluralization_messages[obj.message][x].replace("{n}", obj.n);
                else return "INVALID_MESSAGES_LENGTH";
            } else return "NO_TRANSLATION_DATA";
        },

        getStorageItem: state => key => {
            if (state.storage[key] !== undefined) return state.storage[key];
            return null;
        },

        getCwApplication: state => {
            return state.cw_application;
        }
    },

    mutations: {
        setStorageItem(state, payload) {
            state.storage[payload.key] = payload.value;
        },
        removeStorageItem(state, key) {
            if (state.storage[key] !== undefined) {
                delete state.storage[key];
            }
        },

        addMessage(state, data) {
            state.messages.push({
                type: data.type,
                msg: data.msg,
                use_html: data.use_html || false,
                id: data.id
            });
        },
        removeMessage(state, id) {
            let index = state.messages.findIndex(i => i.id == id);
            if (index != -1) state.messages.splice(index, 1);
        },

        setCwApplication(state, application) {
            if (
                state.cw_application != null &&
                application &&
                state.cw_application._id != application._id
            ) {
                EventBus.emit("Application.cw_application_changed", application);
            }
            state.cw_application = application;
        }
    },

    actions: {
        addMessage({ commit }, data) {
            const id = "message_" + Date.now() + "_" + Math.floor(Math.random() * 10000);

            commit("addMessage", {
                ...data,
                id
            });

            if (!data.duration || data.duration != -1) {
                let dur = 3500;
                if (data.duration) dur = data.duration;

                setTimeout(() => {
                    commit("removeMessage", id);
                }, dur);
            }
        },

        waitForCwApplicationSelection({ state }) {
            if (state.cw_application_promise_handle === null) {
                state.cw_application_promise_handle = new Promise(resolve => {
                    function c() {
                        if (state.cw_application != null) return resolve();
                        else {
                            setTimeout(c, 250);
                        }
                    }
                    c();
                });
            }
            return state.cw_application_promise_handle;
        },

        awaitForServiceWorkerActivation({ state }) {
            if (!state.service_worker_available) {
                return new Promise((resolve, reject) => reject("ServiceWorker not available"));
            }
            return navigator.serviceWorker.ready;
        }
    },

    modules: {
        auth,
        users,
        chat
    }
});
