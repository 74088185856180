export const ChatStatus = Object.freeze({
    PRECREATED: "precreated",
    STARTED: "started",
    ACTIVE: "active",
    INACTIVE: "inactive",
    ARCHIVED: "archived"
});

export const ChatCategory = Object.freeze({
    UNCLAIMED: "unclaimed",
    ACTIVE: "active",
    ARCHIVED: "archived"
});

export const ChatCategoryToStatusMap = Object.freeze({
    [ChatCategory.UNCLAIMED]: [ChatStatus.STARTED],
    [ChatCategory.ACTIVE]: [ChatStatus.ACTIVE, ChatStatus.INACTIVE],
    [ChatCategory.ARCHIVED]: [ChatStatus.ARCHIVED]
});

export function getChatCategoryByStatus(status) {
    if (!Object.values(ChatStatus).includes(status)) throw new Error("Invalid ChatStatus");
    for (const cat in ChatCategoryToStatusMap) {
        if (ChatCategoryToStatusMap[cat].includes(status)) return cat;
    }
    return false;
}
