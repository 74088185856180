import Axios from "axios";
import Store from "@/store";
import waitForMs from "@/helpers/wait-for-ms";
import { messageExists, getMessage } from "./messages";
import {
    accessTokenNeedsRefresh,
    isAuthDataValid,
    refreshTokenCanBeUsed
} from "../helpers/auth-data";

Axios.defaults.baseURL = Store.state.API_BASE_URL + Store.state.API_BASE_PATH;
Axios.defaults.timeout = 30000;

/*

    INTERCEPTOR REQUESTÓW, KTÓRY MA ZA ZADANIE:

    1. Wstrzymać requesty na zabezpieczone adresy do czasu pojawienia się w Store danych autoryzacyjnych (czyli apka się zbootowała i pobrała dane z LS lub odświeżyła je)
    2. W razie potrzeby odświeżyć token autoryzacyjny przed rzuceniem requesta (rozpoznanie po dacie ważności tokenów) - obowiązuje mechanizm kolejki, nie ma opcji na to że każdy robi samodzielnie request na /refresh-token
    3. Dodać token do nagłówka Authorization jeżeli jest w Store

*/
Axios.interceptors.request.use(async function (config) {
    // 1.
    // console.log(config.url);

    const REQUEST_REQUIRES_ACCESS_TOKEN =
        !/.*((\/auth\/(register|login|password-reset-request|password-reset|refresh-token|activate))|\/test)(\/?\?.*)?$/.test(
            config.url
        ) && config.bypass_auth_check !== true;

    if (REQUEST_REQUIRES_ACCESS_TOKEN && Store.state.auth.refreshing_auth_data) {
        while (Store.state.auth.refreshing_auth_data) {
            console.log("waitiong", config.url);
            await waitForMs(500);
        }
    }

    // console.log("awaited", config.url);

    // 2.
    if (Store.state.auth.auth_data != null && REQUEST_REQUIRES_ACCESS_TOKEN) {
        // sprawdzamy czy token nie ulegnie zaraz przedawnieniu (w ciągu minuty) - jak tak to o ile refresh_token na to pozwala odświeżamy go (a przynajmniej próbujemy)
        if (
            accessTokenNeedsRefresh(Store.state.auth.auth_data.access_token_exp_date) &&
            refreshTokenCanBeUsed(Store.state.auth.auth_data.refresh_token_exp_date) &&
            !(config.method.toLowerCase() == "post" && /.*\/auth\/refresh-token$/.test(config.url))
        ) {
            // console.log("will refr at", config.url);
            await Store.dispatch("auth/queueRefreshAuthData", Store.state.auth.auth_data);
            // console.log("token refr", config.url);
        }
    }

    // 3.
    if (Store.state.auth.auth_data != null) {
        config.headers.authorization = "Bearer " + Store.state.auth.auth_data.access_token;
        config.headers["x-authorization"] = "Bearer " + Store.state.auth.auth_data.access_token;
        // console.log("added token", config.url);
    }

    return config;
}, null);

Axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (err) {
        if (err == "Error: Network Error") {
            throw "Network error: could not conect to the remote server";
        }

        if (err?.response?.config?.supress_errors) {
            throw err;
        }

        const o = {
            status: err?.response.status,
            data: err?.response.data
        };

        if (err && err.response && err.response.status === 401) {
            Store.dispatch("addMessage", {
                type: "error",
                msg: "Nie posiadasz uprawnień do tego zasobu"
            });
        } else if (err && err.response && err.response.status === 404) {
            Store.dispatch("addMessage", {
                type: "error",
                msg: "Nie znaleziono szukanego zasobu",
                duration: 5000
            });
        } else if (
            err &&
            err.response &&
            err.response.status >= 400 &&
            err.response.status < 500 &&
            typeof err.response.data == "object" &&
            err.response.data.error_code
        ) {
            //opcjonalne pomienięcie wiadomości o błędzie
            if (err.response?.config?.config?.supress_errors === true) throw o;

            let key = err.config.url.substr(1);
            key = key.split("/")[0];
            const path = key.replace(/\-/g, "_") + ".e" + err.response.data.error_code;

            if (messageExists(path)) {
                Store.dispatch("addMessage", {
                    type: "error",
                    msg: getMessage(path),
                    duration: 5000
                });
            } else {
                Store.dispatch("addMessage", {
                    type: "error",
                    msg: "Nieznany błąd żądania",
                    duration: 5000
                });
            }
        } else {
            Store.dispatch("addMessage", {
                type: "error",
                msg: "Nieoczekiwany błąd serwera",
                duration: 5000
            });
        }

        throw o;
    }
);

function callAxios(config) {
    return new Promise(async (res, rej) => {
        try {
            res((await Axios(config)).data);
        } catch (err) {
            rej(err);
        }
    });
}

export default {
    install(Vue) {
        Vue.prototype.$axios = {
            $get(url, config) {
                return callAxios({
                    url,
                    method: "get",
                    ...config
                });
            },
            $post(url, data, config) {
                return callAxios({
                    method: "post",
                    url,
                    data,
                    ...config
                });
            },
            $put(url, data, config) {
                return callAxios({
                    method: "put",
                    url,
                    data,
                    ...config
                });
            },
            $patch(url, data, config) {
                return callAxios({
                    method: "patch",
                    url,
                    data,
                    ...config
                });
            },
            $delete(url, data, config) {
                return callAxios({
                    method: "delete",
                    url,
                    data,
                    ...config
                });
            }
        };
    }
};
