import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import E404 from "../views/Error404.vue";
import store from "../store";
import waitForMs from "../helpers/wait-for-ms";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },

    // AUTH ROUTES
    {
        path: "/auth/logowanie",
        name: "auth-login",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/login.vue")
    },
    {
        path: "/auth/rejestracja",
        name: "auth-register",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/register.vue")
    },
    {
        path: "/auth/nie-pamietam-hasla",
        name: "auth-password-reset-request",
        component: () =>
            import(/* webpackChunkName: "auth" */ "../views/auth/password-reset-request.vue")
    },
    {
        path: "/auth/resetowanie-hasla",
        name: "auth-password-reset",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/password-reset.vue")
    },

    // MY ACCOUNT
    {
        path: "/moje-konto",
        name: "my-account__main",
        component: () =>
            import(/* webpackChunkName: "my-account" */ "../views/my-account/index.vue")
    },

    // USERS MODULE
    {
        path: "/uzytkownicy",
        name: "users__list",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/index.vue")
    },
    {
        path: "/uzytkownicy/:id",
        name: "users__edit",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/edit.vue")
    },

    // APPLICATIONS MODULE
    {
        path: "/aplikacje",
        name: "applications__list",
        component: () =>
            import(/* webpackChunkName: "applications" */ "../views/applications/index.vue")
    },

    // BLOG POSTS MODULE
    {
        path: "/wpisy-na-blogu",
        name: "blog_posts__list",
        component: () =>
            import(/* webpackChunkName: "blog-posts" */ "../views/blog-posts/index.vue")
    },
    {
        path: "/wpisy-na-blogu/:id",
        name: "blog_posts__edit",
        component: () => import(/* webpackChunkName: "blog-posts" */ "../views/blog-posts/edit.vue")
    },

    // FAQ QUESTIONS MODULE
    {
        path: "/pytania-faq",
        name: "faq_questions__list",
        component: () =>
            import(/* webpackChunkName: "faq-questions" */ "../views/faq-questions/index.vue")
    },
    {
        path: "/pytania-faq/:id",
        name: "faq_questions__edit",
        component: () =>
            import(/* webpackChunkName: "faq-questions" */ "../views/faq-questions/edit.vue")
    },

    // CHATS MODULE
    {
        path: "/chats",
        component: () => import(/* webpackChunkName: "chats" */ "../views/chats/layout.vue"),

        children: [
            {
                path: "",
                name: "chats__list",
                component: () => import(/* webpackChunkName: "chats" */ "../views/chats/index.vue")
            },
            {
                path: ":id",
                name: "chats__details",
                component: () => import(/* webpackChunkName: "chats" */ "../views/chats/chat.vue")
            }
        ]
    },

    // WP BLOG MODULE
    {
        path: "/wp-blog",
        name: "wpblog__list",
        component: () => import(/* webpackChunkName: "wp-blog" */ "../views/wp-blog/index.vue")
    },
    {
        path: "/wp-blog/:id",
        name: "wpblog__details",
        component: () => import(/* webpackChunkName: "wp-blog" */ "../views/wp-blog/details.vue")
    },

    // REELS MODULE
    {
        path: "/reels",
        name: "reels__list",
        component: () => import(/* webpackChunkName: "reels" */ "../views/reels/index.vue")
    },
    {
        path: "/reels/:id",
        name: "reels__edit",
        component: () => import(/* webpackChunkName: "reels" */ "../views/reels/edit.vue")
    },

    {
        path: "/offline",
        name: "offline",
        component: () => import(/* webpackChunkName: "offline" */ "../views/offline.vue")
    },
    {
        path: "*",
        name: "error-404",
        component: E404
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

const UNPROTECTED_PATHS = [
    "/auth/rejestracja",
    "/auth/logowanie",
    "/auth/odblokowanie-konta",
    "/auth/resetowanie-hasla",
    "/auth/nie-pamietam-hasla"
];

router.beforeEach(async (to, from, next) => {
    // jeżeli apka się jeszcze nie zbootowała to grzecznie czekamy
    while (!store.state.app_booted) {
        await waitForMs(250);
    }

    // jeżeli użytkownik próbuje się dostać na zabezpieczony route to sprawdzamy jego uprawnienia - musi być tylko zalogowany i nie role == user, resztę załatwi UI i 401
    if (
        UNPROTECTED_PATHS.indexOf(to.path) == -1 &&
        (!store.getters["auth/isLoggedIn"] ||
            (store.getters["auth/isLoggedIn"] && store.getters["auth/userRole"] == "user"))
    ) {
        return next("/auth/logowanie?rback=" + encodeURIComponent(to.path));
    }

    return next();
});

export default router;
