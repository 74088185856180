<template>
    <div class="default-layout">
        <v-navigation-drawer
            app
            v-model="sidemenu"
        >
            <!-- KARTA AKTYWNEJ APLIKACJI -->
            <v-card
                outlined
                class="ma-2"
                style="cursor: pointer"
                @click="
                    () => {
                        if (selected_application) {
                            appsel_dialog_selected_id = selected_application._id;
                        }
                        appsel_dialog = true;
                    }
                "
            >
                <div
                    class="d-flex align-center pa-2"
                    v-if="selected_application"
                >
                    <div class="flex-shrink-0">
                        <v-avatar
                            color="primary"
                            size="36"
                            rounded
                        >
                            <v-img
                                v-if="selected_application.icon_64"
                                :src="selected_application.icon_64"
                            ></v-img>
                            <v-icon
                                v-else
                                color="primary lighten-1"
                                size="20"
                            >
                                mdi-rhombus-split
                            </v-icon>
                        </v-avatar>
                    </div>
                    <div class="flex-grow-1 ml-2">
                        <p
                            class="text-caption grey--text ma-0 mb-1 pa-0"
                            style="line-height: 1"
                        >
                            Aplikacja:
                        </p>
                        <p
                            class="text-body-2 font-weight-medium ma-0 pa-0 text-truncate"
                            :title="selected_application.name"
                            style="max-width: 140px; line-height: 1"
                        >
                            {{ selected_application.name }}
                        </p>
                    </div>
                    <div class="flex-shrink-0 ml-2">
                        <v-btn
                            small
                            icon
                            title="Przełącz aplikację"
                        >
                            <v-icon color="primary lighten-3"> mdi-chevron-down </v-icon>
                        </v-btn>
                    </div>
                </div>
                <v-skeleton-loader
                    v-else
                    type="list-item-avatar-two-line"
                ></v-skeleton-loader>
            </v-card>

            <v-divider></v-divider>

            <v-list
                dense
                nav
            >
                <v-list-item
                    v-for="item in filteredNavItems"
                    :key="item.title"
                    :to="item.to"
                    :exact="item.exact === true"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon @click="sidemenu = !sidemenu">
                <v-icon>{{ sidemenu ? "mdi-arrow-collapse-left" : "mdi-menu" }}</v-icon>
            </v-app-bar-nav-icon>
            <v-spacer></v-spacer>

            <!-- <NotificationsList /> -->
            <UserTile
                :user="user"
                tag="router-link"
                :to="{
                    name: 'my-account__main'
                }"
                details-key="role"
                hide-name-details
                class="ml-2"
            />
            <v-btn
                icon
                small
                class="ml-2"
                title="Wyloguj"
                @click="$store.dispatch('auth/logOut')"
            >
                <v-icon :size="20">mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <transition
                    :name="
                        $route.name != 'chats__list' && $route.name != 'chats__details'
                            ? 'fade'
                            : 'none'
                    "
                    mode="out-in"
                >
                    <div
                        class="pa-0 pa-md-3"
                        :key="$route.fullPath"
                    >
                        <slot />
                    </div>
                </transition>
            </v-container>
        </v-main>

        <!-- SELECT APP DIALOG -->
        <v-dialog
            v-model="appsel_dialog"
            scrollable
            max-width="450"
            :persistent="appsel_dialog_persistent"
        >
            <v-card>
                <v-card-title>Przełącz aplikację</v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pa-0"
                    style="max-height: 70vh"
                >
                    <v-list class="pa-0">
                        <v-list-item-group
                            color="primary"
                            v-model="appsel_dialog_selected_id"
                            mandatory
                        >
                            <v-list-item
                                v-for="app in applications"
                                :key="app._id"
                                class="px-6"
                                :value="app._id"
                                :disabled="app.active == false"
                                :style="app.active == false ? 'opacity: .75' : ''"
                            >
                                <v-list-item-avatar
                                    color="primary"
                                    rounded
                                    size="48"
                                >
                                    <v-img
                                        v-if="app.icon_64"
                                        :src="app.icon_64"
                                    ></v-img>
                                    <v-icon
                                        v-else
                                        color="primary lighten-1"
                                        size="32"
                                    >
                                        mdi-rhombus-split
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        {{ app.name }}
                                    </v-list-item-title>
                                    <p
                                        v-if="app._id == appsel_dialog_selected_id"
                                        class="text-caption success--text font-weight-medium ma-0"
                                        style="line-height: 12px"
                                    >
                                        Wybrana
                                    </p>
                                    <p
                                        v-else-if="app.active == false"
                                        class="text-caption font-weight-medium ma-0"
                                        style="line-height: 12px"
                                    >
                                        Nieaktywna
                                    </p>
                                </v-list-item-content>
                            </v-list-item>
                            <div
                                style="height: 1px; margin-top: -1px"
                                v-intersect="onApplicationsIoIntersect"
                            ></div>
                            <div
                                class="text-center my-2"
                                v-if="applications_loading"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-1"
                                    size="24"
                                    width="2"
                                ></v-progress-circular>
                            </div>
                        </v-list-item-group>

                        <v-list-item
                            v-if="applications_loaded && applications.length === 0"
                            class="px-6"
                        >
                            <v-list-item-content class="text-center text-caption grey--text">
                                Brak danych do wyświetlenia
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color="grey"
                        text
                        v-if="!appsel_dialog_persistent"
                        @click="appsel_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        text
                        @click="selectCwApplication"
                    >
                        Potwierdź wybór
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import NotificationsList from "@/components/NotificationsList";
import {
    saveInLocalStorage,
    retrieveFromLocalStorage,
    removeFromLocalStorage
} from "@/helpers/local-storage";

const CACHE_KEYS = Object.freeze({
    SELECTED_APP: "default::selected_app"
});

export default {
    // components: {
    //     NotificationsList
    // },

    data() {
        return {
            sidemenu: true,

            nav_items: [
                {
                    icon: "mdi-home",
                    title: "Dashboard",
                    to: {
                        name: "home"
                    },
                    required_permissions: [],
                    exact: true
                },
                {
                    icon: "mdi-forum",
                    title: "Konwersacje",
                    to: {
                        name: "chats__list"
                    },
                    required_permissions: "chats.list"
                },
                {
                    icon: "mdi-video-box",
                    title: "Reels",
                    to: {
                        name: "reels__list"
                    },
                    required_permissions: "reels.list"
                },
                {
                    icon: "mdi-post",
                    title: "Wpisy na blogu",
                    to: {
                        name: "blog_posts__list"
                    },
                    required_permissions: "blog_posts.list"
                },
                {
                    icon: "mdi-post",
                    title: "Blog WordPress",
                    to: {
                        name: "wpblog__list"
                    },
                    required_permissions: "wordpress_blog_posts.list"
                },
                {
                    icon: "mdi-frequently-asked-questions",
                    title: "Pytania FAQ",
                    to: {
                        name: "faq_questions__list"
                    },
                    required_permissions: "faq_questions.list"
                },
                {
                    icon: "mdi-apps",
                    title: "Aplikacje",
                    to: {
                        name: "applications__list"
                    },
                    required_permissions: "applications.list"
                },
                {
                    icon: "mdi-account-group",
                    title: "Użytkownicy",
                    to: {
                        name: "users__list"
                    },
                    required_permissions: "users.list"
                }
            ],

            applications: [],
            applications_loading: false,
            applications_loaded: false,
            applications_pagination_current_page: 0,
            applications_pagination_number_of_pages: 1,

            appsel_dialog: false,
            appsel_dialog_persistent: false,
            appsel_dialog_selected_id: null
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user || {};
        },
        filteredNavItems() {
            return this.nav_items.filter(item =>
                this.$store.getters["auth/userHasPermissions"](item.required_permissions)
            );
        },

        selected_application() {
            return this.$store.getters["getCwApplication"];
        }
    },

    methods: {
        async fetchApplications() {
            if (this.applications_loading) return;
            this.applications_loading = true;

            try {
                const r = await this.$axios.$get(
                    `/applications/?page=${
                        this.applications_pagination_current_page + 1
                    }&items_per_page=16`
                );

                const A = [];
                for (let i = 0; i < r.applications.length; i++) {
                    const ix = this.applications.findIndex(it => it._id == r.applications[i]._id);
                    if (ix === -1) {
                        A.push(r.applications[i]);
                    }
                }

                if (A.length > 0) {
                    this.applications = this.applications.concat(A);
                }

                this.applications_loaded = true;
                this.applications_pagination_number_of_pages = r.pagination.number_of_pages;
                this.applications_pagination_current_page = r.pagination.current_page;
            } catch (err) {
                console.error(err);
            }

            this.applications_loading = false;
        },
        onApplicationsIoIntersect(entries, observer, isIntersecting) {
            if (
                isIntersecting &&
                this.applications_pagination_current_page <
                    this.applications_pagination_number_of_pages
            ) {
                this.fetchApplications();
            }
        },

        // onApplicationCreated(application) {
        //     if(this.applications.findIndex(it => it._id == application._id) === -1){
        //         this.applications.unshift(application);
        //     }
        // },
        onApplicationUpdated(application) {
            const ix = this.applications.findIndex(el => el._id == application._id);
            if (ix !== -1) {
                this.applications.splice(ix, 1, application);
            }

            if (this.selected_application && this.selected_application._id == application._id) {
                if (application.active === false) {
                    // aplikacja została zdezaktywowana - wymuszamy wybór innej
                    this.$store.commit("setCwApplication", null);
                    this.appsel_dialog = true;
                    this.appsel_dialog_persistent = true;
                    this.$message({
                        type: "error",
                        msg: "Wybrana przez Ciebie Aplikacja została właśnie wyłączona przez Administratora. Wybierz inną, aby kontynuować pracę.",
                        duration: 5000
                    });
                } else {
                    this.$store.commit("setCwApplication", application);
                }
            }
        },

        selectCwApplication() {
            const ITEM = this.applications.find(it => it._id === this.appsel_dialog_selected_id);
            if (!ITEM) return;

            this.$store.commit("setCwApplication", ITEM);
            saveInLocalStorage(CACHE_KEYS.SELECTED_APP, ITEM._id);

            this.appsel_dialog = false;
            this.appsel_dialog_selected_id = null;
            this.appsel_dialog_persistent = false;
        }
    },

    async mounted() {
        if (window.innerWidth < 600) {
            this.$nextTick(_ => {
                this.sidemenu = false;
            });
        }

        const LS_SELECTED_APP = retrieveFromLocalStorage(CACHE_KEYS.SELECTED_APP);

        let cached_app_object = null;
        if (LS_SELECTED_APP && LS_SELECTED_APP !== "") {
            try {
                const r = await this.$axios.$get(`/applications/${LS_SELECTED_APP}`, {
                    supress_errors: true
                });
                cached_app_object = r.application;
            } catch (err) {
                console.error(err);
                removeFromLocalStorage(CACHE_KEYS.SELECTED_APP);
            }
        }

        if (cached_app_object != null && cached_app_object.active === true) {
            // ładujemy tego z cache i jazda
            this.$store.commit("setCwApplication", cached_app_object);
        } else {
            // wyświetlamy dialog i zmuszamy do działania
            this.appsel_dialog = true;
            this.appsel_dialog_persistent = true;
        }

        // this.$io.on("Application.created", this.onApplicationCreated);
        this.$io.on("Application.updated", this.onApplicationUpdated);
    },

    beforeDestroy() {
        this.$io.off("Application.updated", this.onApplicationUpdated);
    }
};
</script>
