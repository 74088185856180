import { EventBus } from "../../components/EventBus";
import waitForMs from "../../helpers/wait-for-ms";
import { ChatCategory, ChatCategoryToStatusMap, getChatCategoryByStatus } from "../../enums/Chat";
import { ChatMessageAuthorType, ChatMessageStatus } from "../../enums/ChatMessage";

export default {
    namespaced: true,

    state: () => ({
        chats: [],
        chats_pagination: {
            [ChatCategory.UNCLAIMED]: {
                loading: false,
                current_page: 0,
                number_of_pages: 1,
                items_per_page: 16,
                number_of_items: false
            },
            [ChatCategory.ACTIVE]: {
                loading: false,
                current_page: 0,
                number_of_pages: 1,
                items_per_page: 16,
                number_of_items: false
            },
            [ChatCategory.ARCHIVED]: {
                loading: false,
                current_page: 0,
                number_of_pages: 1,
                items_per_page: 16,
                number_of_items: false
            }
        },

        last_active_tab: ChatCategory.UNCLAIMED,
        sp_cache: {
            unassigned: 0,
            active: 0,
            archived: 0
        },
        filter_cache: {
            active: true,
            archived: true
        },

        chats_mounted: false,
        active_chat: null,

        chat_messages: []
    }),

    getters: {
        findChat: state => id => {
            return state.chats.find(it => it._id == id);
        },
        getChats: state => {
            return state.chats.sort((a, b) => b.la_date - a.la_date);
        },
        getChatsFilteredByCategory: state => category => {
            if (!ChatCategoryToStatusMap[category]) return [];
            return state.chats
                .filter(it => ChatCategoryToStatusMap[category].includes(it.status))
                .sort((a, b) => b.la_date - a.la_date);
        },

        hasUnreadMessages: (state, getters) => chat_id => {
            return (
                state.chat_messages
                    .filter(it => it.chat == chat_id)
                    .findIndex(
                        it =>
                            it.author_type == ChatMessageAuthorType.CUSTOMER &&
                            it.status != ChatMessageStatus.READ
                    ) !== -1
            );
        },

        getChatMessages: state => chat => {
            return state.chat_messages
                .filter(it => it.chat == chat)
                .sort((a, b) => b.c_date - a.c_date);
        },

        // zwraca string z datą i/lub godziną w zależności od tego jak w stosunku do teraz plasuje się zadana data
        parseDate:
            state =>
            (d, opts = {}) => {
                if (typeof d != "number") {
                    d = new Date(d).getTime();
                }

                const now = Date.now();

                if (now - d <= 0) {
                    return "Invalid input date";
                }

                const now_date = new Date(now);
                const d_date = new Date(d);

                const weekdays = [
                    {
                        full: "Niedziela",
                        short: "nd."
                    },
                    {
                        full: "Poniedziałek",
                        short: "pn."
                    },
                    {
                        full: "Wtorek",
                        short: "wt."
                    },
                    {
                        full: "Środa",
                        short: "śr."
                    },
                    {
                        full: "Czwartek",
                        short: "czw."
                    },
                    {
                        full: "Piątek",
                        short: "pt."
                    },
                    {
                        full: "Sobota",
                        short: "sob."
                    }
                ];

                // 1. jeżeli między datami jest < 24h różnicy i jednocześnie jest to ta sama data to zwracamy sam stamp godzinowy
                if (now - d < 24 * 60 * 60 * 1000 && now_date.getDate() === d_date.getDate()) {
                    return `${d_date.getHours().toString().pad(2)}:${d_date
                        .getMinutes()
                        .toString()
                        .pad(2)}`;
                }

                // 2. jeżeli jest mniej niż 7 dni różnicy i jest to jednocześnie ten sam tydzień to zwracamy skróconą nazwę dnia tygodnia + godzinę
                if (now - d < 7 * 24 * 60 * 60 * 1000 && now_date.getWeek() == d_date.getWeek()) {
                    return `${weekdays[d_date.getDay()].short} ${d_date
                        .getHours()
                        .toString()
                        .pad(2)}:${d_date.getMinutes().toString().pad(2)}`;
                }

                // 3. jeżeli poprzednie przypadki nie złapały to zwracamy według przekazanych opcji
                if (opts.hide_time === true) {
                    return `${d_date.getDate().toString().pad(2)}.${(d_date.getMonth() + 1)
                        .toString()
                        .pad(2)}.${d_date.getFullYear()}`;
                }
                return `${d_date.getDate().toString().pad(2)}.${(d_date.getMonth() + 1)
                    .toString()
                    .pad(2)}.${d_date.getFullYear()} ${d_date.getHours().toString().pad(2)}:${d_date
                    .getMinutes()
                    .toString()
                    .pad(2)}`;
            }
    },

    actions: {
        // 1. CHATS
        addChats({ state, getters }, chats = []) {
            if (!Array.isArray(chats)) chats = [chats];

            const A = [];

            for (let i = 0; i < chats.length; i++) {
                if (getters.findChat(chats[i]._id) != null) return;

                const O = JSON.parse(JSON.stringify(chats[i]));
                O.chm_pagination_current_page = 0;
                O.chm_pagination_number_of_pages = 1;
                O.chm_pagination_number_of_items = 0;
                O.chm_loading = false;
                O.chm_loaded = false;

                if (O.__from_socket_io === true) {
                    const CATEGORY = getChatCategoryByStatus(O.status);
                    if (CATEGORY !== false) {
                        state.chats_pagination = {
                            ...state.chats_pagination,
                            [CATEGORY]: {
                                ...state.chats_pagination[CATEGORY],
                                number_of_items:
                                    state.chats_pagination[CATEGORY].number_of_items !== false
                                        ? state.chats_pagination[CATEGORY].number_of_items + 1
                                        : 1
                            }
                        };
                    }
                    delete O.__from_socket_io;
                }

                A.push(O);
            }

            if (A.length > 0) {
                state.chats = state.chats.concat(A);
            }
        },
        updateChat({ state }, chat) {
            const ix = state.chats.findIndex(it => it._id == chat._id);
            if (ix === -1) return;

            const OLD_CATEGORY = getChatCategoryByStatus(state.chats[ix].status);
            const NEW_CATEGORY =
                chat.status !== undefined ? getChatCategoryByStatus(chat.status) : false;

            state.chats.splice(ix, 1, {
                ...state.chats[ix],
                ...chat
            });

            // przy zmianie kategorii zmiana w ilości elementów dla paginacji
            if (chat.status != undefined && OLD_CATEGORY != NEW_CATEGORY) {
                if (OLD_CATEGORY === false && NEW_CATEGORY !== false) {
                    state.chats_pagination = {
                        ...state.chats_pagination,
                        [NEW_CATEGORY]: {
                            ...state.chats_pagination[NEW_CATEGORY],
                            number_of_items:
                                state.chats_pagination[NEW_CATEGORY].number_of_items + 1
                        }
                    };
                } else if (OLD_CATEGORY !== false && NEW_CATEGORY === false) {
                    state.chats_pagination = {
                        ...state.chats_pagination,
                        [OLD_CATEGORY]: {
                            ...state.chats_pagination[OLD_CATEGORY],
                            number_of_items:
                                state.chats_pagination[OLD_CATEGORY].number_of_items - 1
                        }
                    };
                } else if (OLD_CATEGORY !== false && NEW_CATEGORY !== false) {
                    state.chats_pagination = {
                        ...state.chats_pagination,
                        [OLD_CATEGORY]: {
                            ...state.chats_pagination[OLD_CATEGORY],
                            number_of_items:
                                state.chats_pagination[OLD_CATEGORY].number_of_items - 1
                        },
                        [NEW_CATEGORY]: {
                            ...state.chats_pagination[NEW_CATEGORY],
                            number_of_items:
                                state.chats_pagination[NEW_CATEGORY].number_of_items + 1
                        }
                    };
                }

                if (
                    NEW_CATEGORY !== false &&
                    state.last_active_tab != NEW_CATEGORY &&
                    state.active_chat == chat._id
                ) {
                    state.last_active_tab = NEW_CATEGORY;
                    EventBus.emit("ChatCategory.toggle", {
                        category: NEW_CATEGORY,
                        force_open: true
                    });
                }
            }
        },
        deleteChat({ state }, chat_id) {
            const ix = state.chats.findIndex(it => it._id == chat_id);
            if (ix === -1) return;

            const CATEGORY = getChatCategoryByStatus(state.chats[ix].status);
            state.chats.splice(ix, 1);

            if (CATEGORY !== false) {
                state.chats_pagination = {
                    ...state.chats_pagination,
                    [CATEGORY]: {
                        ...state.chats_pagination[CATEGORY],
                        number_of_items: state.chats_pagination[CATEGORY].number_of_items - 1
                    }
                };
            }
        },

        fetchChats({ state, rootState }, category) {
            return new Promise(async (resolve, reject) => {
                if (Object.values(ChatCategory).indexOf(category) === -1) {
                    return reject("Invalid Chat category");
                }
                if (state.chats_pagination[category].loading) {
                    function check() {
                        if (state.chats_pagination[category].loading) {
                            setTimeout(check, 500);
                        } else {
                            return resolve();
                        }
                    }
                    return check();
                }

                if (
                    state.chats_pagination[category].current_page >=
                    state.chats_pagination[category].number_of_pages
                ) {
                    return resolve();
                }

                state.chats_pagination = {
                    ...state.chats_pagination,
                    [category]: {
                        ...state.chats_pagination[category],
                        loading: true
                    }
                };

                try {
                    const RQ = [
                        "application=" + rootState.cw_application?._id,
                        "page=" + state.chats_pagination[category].current_page + 1,
                        "items_per_page=32"
                    ];

                    if (ChatCategoryToStatusMap[category].length === 1) {
                        RQ.push("filter_status=" + ChatCategoryToStatusMap[category][0]);
                    } else {
                        for (let i = 0; i < ChatCategoryToStatusMap[category].length; i++) {
                            RQ.push("filter_status[]=" + ChatCategoryToStatusMap[category][i]);
                        }
                    }

                    const r = await this._vm.$axios.$get(`/chats/?${RQ.join("&")}`);
                    // console.log(r);

                    this.dispatch("chat/addChats", r.chats);
                    await waitForMs(20);

                    state.chats_pagination = {
                        ...state.chats_pagination,
                        [category]: {
                            ...state.chats_pagination[category],
                            current_page: r.pagination.current_page,
                            number_of_pages: r.pagination.number_of_pages,
                            items_per_page: r.pagination.items_per_page,
                            number_of_items: r.pagination.number_of_items
                        }
                    };

                    state.chats_pagination = {
                        ...state.chats_pagination,
                        [category]: {
                            ...state.chats_pagination[category],
                            loading: false
                        }
                    };

                    return resolve();
                } catch (err) {
                    console.error(err);

                    state.chats_pagination = {
                        ...state.chats_pagination,
                        [category]: {
                            ...state.chats_pagination[category],
                            loading: false
                        }
                    };

                    return reject(err);
                }
            });
        },

        // 2. CHAT MESSAGES
        addChatMessages({ state }, chat_messages = []) {
            if (!Array.isArray(chat_messages)) chat_messages = [chat_messages];
            const A = [];

            for (let i = 0; i < chat_messages.length; i++) {
                const ix = state.chat_messages.findIndex(it => it._id == chat_messages[i]._id);
                if (ix !== -1) return;

                A.push(chat_messages[i]);
            }

            if (A.length > 0) {
                state.chat_messages = state.chat_messages.concat(A);
            }
        },
        updateChatMessage({ state }, chat_message) {
            const ix = state.chat_messages.findIndex(it => it._id == chat_message._id);
            if (ix === -1) return;

            if (chat_message.update_data != undefined) {
                state.chat_messages.splice(ix, 1, {
                    ...state.chat_messages[ix],
                    ...chat_message.update_data
                });
            } else {
                state.chat_messages.splice(ix, 1, {
                    ...state.chat_messages[ix],
                    ...chat_message
                });
            }
        },
        removeChatMessage({ state }, id) {
            const ix = state.chat_messages.findIndex(it => it._id == id);
            if (ix === -1) return;

            state.chat_messages.splice(ix, 1);
        },

        fetchChatMessages({ state, getters, rootState }, chat_id) {
            return new Promise(async (resolve, reject) => {
                const ix = state.chats.findIndex(it => it._id == chat_id);
                if (ix === -1) return reject("Chat not found");

                if (state.chats[ix].chm_loading) {
                    function check() {
                        if (state.chats[ix].chm_loading) {
                            setTimeout(check, 500);
                        } else {
                            return resolve();
                        }
                    }
                    return check();
                }

                if (
                    state.chats[ix].chm_pagination_current_page >=
                    state.chats[ix].chm_pagination_number_of_pages
                ) {
                    return resolve();
                }

                this.dispatch("chat/updateChat", {
                    _id: chat_id,
                    chm_loading: true
                });

                try {
                    const r = await this._vm.$axios.$get(
                        `/chat-messages/?page=${
                            state.chats[ix].chm_pagination_current_page + 1
                        }&items_per_page=32&chat=${chat_id}`
                    );
                    // console.log(r);

                    this.dispatch("chat/addChatMessages", r.chat_messages);

                    if (r.chat_messages.length > 0) {
                        // jeżeli to jest pierwsza strona, to oznaczamy wszystkie jako dostarczone
                        if (
                            r.pagination.current_page === 1 &&
                            state.chats[ix].assigned_user &&
                            state.chats[ix].assigned_user._id === rootState.auth.user._id
                        ) {
                            try {
                                await this._vm.$axios.$put(`/chat-messages/batch/status`, {
                                    status: ChatMessageStatus.DELIVERED,
                                    to_id: r.chat_messages[0]._id
                                });
                            } catch (err2) {
                                console.error(err2);
                            }
                        }
                    }

                    this.dispatch("chat/updateChat", {
                        _id: chat_id,
                        chm_loading: false,
                        chm_loaded: true,
                        chm_pagination_current_page: r.pagination.current_page,
                        chm_pagination_number_of_pages: r.pagination.number_of_pages,
                        chm_pagination_number_of_items: r.pagination.number_of_items
                    });

                    return resolve();
                } catch (err) {
                    console.error(err);

                    this.dispatch("chat/updateChat", {
                        _id: chat_id,
                        chm_loading: false
                    });

                    return reject(err);
                }
            });
        }
    }
};
