export const UserRole = Object.freeze({
    ACCOUNT_MANAGER: "account_manager",
    PROJECT_MANAGER: "project_manager",
    ADMIN: "admin"
});
export const UserRoleLabel = Object.freeze({
    [UserRole.ACCOUNT_MANAGER]: "Agent",
    [UserRole.PROJECT_MANAGER]: "Project Manager",
    [UserRole.ADMIN]: "Administrator"
});
export const UserRoleColor = Object.freeze({
    [UserRole.ACCOUNT_MANAGER]: "purple",
    [UserRole.PROJECT_MANAGER]: "teal",
    [UserRole.ADMIN]: "primary"
});

export const UserStatus = Object.freeze({
    CREATED: "created",
    ACTIVE: "active",
    SUSPENDED: "suspended"
});
export const UserStatusLabel = Object.freeze({
    [UserStatus.CREATED]: "Wstępnie utworzone",
    [UserStatus.ACTIVE]: "Aktywne",
    [UserStatus.SUSPENDED]: "Zawieszone"
});
export const UserStatusColor = Object.freeze({
    [UserStatus.CREATED]: "secondary",
    [UserStatus.ACTIVE]: "success",
    [UserStatus.SUSPENDED]: "grey"
});
