/*******************************************************************************
 *
 * PLIK ZAWIERAJĄCY MESSAGES MAJĄCE ZASTĘPOWAĆ ZWROTKI Z API PO polu error_code
 * ORAZ HELPERY DO ICH POBIERANIA
 *
 *******************************************************************************/
const MESSAGES = {
    auth: {
        e101: "Nieprawidłowy lub nieważny token rejestracji. Skontaktuj się z Administratorem, aby wysłał do Ciebie nowe zaproszenie.",

        e201: "Nieprawidłowy e-mail lub hasło",
        e202: "Dokończ rejestrację, by móc się zalogować",
        e203: "Konto zostało zawieszone przez Administrację serwisu",
        e204: "Konto zostało tymczasowo zablokowane z powodu wielu nieudanych prób logowania. Spróbuj ponownie za kilka minut.",

        e301: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut",

        e401: "Nieprawidłowy lub nieważny token zmiany hasła.",

        e501: "Nieprawidłowy lub nieważny refresh_token"
    },

    users: {
        e201: "Istnieje już w systemie konto powiązane z podanym adresem e-mail",

        e502: "Wiadomość z zaproszeniem można wysłać raz na 5 minut",

        e602: "Nie możesz usunąć swojego konta",
        e603: "Podano nieprawidłowe hasło"
    },

    me: {
        e301: "Podano nieprawidłowe obecne hasło",

        e402: "Rozdzielczość przesłanego obrazu jest zbyt niska",
        e403: "Rozdzielczość przesłanego obrazu jest zbyt wysoka"
    },

    blog_posts: {
        e402: "Istnieje już inny wpis o takim tytule URL"
    },

    faq_questions: {
        e402: "Istnieje już inne pytanie o takim tytule URL"
    },

    faq_tags: {
        e201: "Istnieje już inny tag o podanej nazwie",
        e402: "Istnieje już inny tag o podanej nazwie"
    },

    push_notification_queues: {
        e402: "Nie można edytować elementu, który jest na statusie dalszym niż 'W kolejce'",
        e502: "Nie można usunąć elementu, który jest na statusie dalszym niż 'W kolejce'"
    },

    wordpress_blog_posts: {
        e503: "Funkcja synchronizacji z WordPress nie została skonfigurowana dla tej Aplikacji",
        e504: "Aktualnie trwa już proces synchronizacji z WordPress"
    },

    reels: {
        e405: "Ten użytkownik nie posiada uprawnień do edycji elementów w Aplikacji, do której należy ten Reel. W związku z tym, nie może zostać jego autorem.",
        e406: "Reel nie może zostać opublikowany, dopóki jego wideo nie zostanie przetworzone.",

        e506: "Nowe wideo można wgrać dopiero po zakończeniu przetwarzania obecnego.",

        e604: "Nie można usunąć Reel, dopóki trwa przetwarzanie jego wideo."
    },

    chats: {
        e406: "Wskazany użytkownik nie posiada uprawnień do uczestniczenia w konwersacjach tej Aplikacji"
    }
};

function getNestedObjProperty(obj, property) {
    let work_obj = obj;
    const splitted_property = property.split(".");

    for (let i = 0; i < splitted_property.length; i++) {
        if (typeof work_obj != "object" || work_obj[splitted_property[i]] == undefined) {
            return null;
        }

        work_obj = work_obj[splitted_property[i]];
    }

    return work_obj;
}

export function messageExists(path) {
    if (!path || path == "") return false;
    return getNestedObjProperty(MESSAGES, path) !== null;
}

export function getMessage(path) {
    if (!path || path == "") return "";
    const t = getNestedObjProperty(MESSAGES, path);
    return t === null ? path : t;
}
