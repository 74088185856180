<template>
    <div class="auth-layout">
        <div class="auth-layout__wrapper">
            <div class="auth-layout__content">
                <div class="auth-layout__logos">
                    <img
                        :src="$store.state.SELF_URL + '/static/imgs/pravna-logo-color.svg'"
                        alt="Logo PRAVNA Group"
                    />
                </div>
                <h1 class="text-h6 text-center mt-2 font-weight-bold">Panel Administracyjny</h1>
                <transition
                    name="fade"
                    mode="out-in"
                >
                    <div :key="$route.fullPath">
                        <slot />
                    </div>
                </transition>
            </div>
            <div class="auth-layout__footer mt-2">
                <div class="text-center text-caption grey--text">
                    &copy;{{ new Date().getFullYear() }} PRAVNA Group Sp. z o.o.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },

    metaInfo: {
        htmlAttrs: {
            class: ["ns"]
        },
        bodyAttrs: {
            class: ["ns"]
        }
    }
};
</script>
